/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import {
  Form, Image, Switch, AutoComplete,
} from 'antd';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createPromosSetup,
  getMembers,
  getMembersByID,
  getPromosSetup,
  updatePromosSetup,
} from 'redux-store/promosSetup/promosSetupActions';
import { clearData } from 'redux-store/promosSetup/promosSetupSlice';
import Button from 'shared/components/button/Button';
import CheckBox from 'shared/components/checkbox/CheckBox';
import DatePicker from 'shared/components/datePicker/DatePicker';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import DropDown from 'shared/components/dropdown/DropDown';
import { isNew } from 'shared/utils/global';
import {
  applyTrim, blackListedChars, emptyFieldValidator, allowOnlyNumbers,
  allowOnlyANSI,
  allowDecimalNumbers,
} from 'shared/utils/validators';
import './_promos.scss';
import RadioGroup from 'shared/components/radioGroup/RadioGroup';
import UploadButton from 'shared/components/upload/Upload';
import InputField from 'shared/components/inputField/InputField';
import TextAreaField from 'shared/components/textArea/TextArea';
import RadioButton from 'shared/components/radiobutton/RadioButton';
import StatesDropDown from 'shared/components/statesDropDown/StatesDropDown';
import DeliveryMethods from 'shared/components/deliveryMethods/DeliveryMethods';
import {
  TrashIcon,
} from 'utils/grid/columns';
import MultiCheckboxSelect from 'shared/components/multiCheckboxSelect/MultiCheckboxSelect';
import { getStartOfDayStringFromDateString, getStartOfNextDayFromDateString } from 'shared/utils/helper';
import LinkedProcedure from '../procedureManagement/components/addMedicationSets/LinkedProcedure';
import PreviewPromo from './Preview';

const promoCodeTypes = [
  { label: 'Membership Fees', value: 'membershipFees' },
  { label: 'Procedures', value: 'procedures' },
  { label: 'At Home Labs', value: 'labs' },
  { label: 'Programs (% off only)', value: 'programs' },
  { label: 'Off Cart', value: 'offCart' },
];

export default function AddNewPromo() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [goLive, setGoLive] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [showCopied, setShowCopeied] = useState(false);
  const {
    selectedPromosSetupSlice,
    isLoading,
    members,
    membersSelected,
  } = useSelector((state) => state.promosSetups);
  const hoursOfDay = new Array(24).fill(null).map((_a, i) => ({ value: i + 1, label: `${i + 1} ${i + 1 === 1 ? 'hour' : 'hours'}` }));
  const [dialogOpen, setDialogOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/promo/promos');
  };

  const handleSubmit = async (vals) => {
    setError(false);
    if ((form.getFieldValue('durationInHours') === true
    || form.getFieldValue('durationDateRange') === true
    || form.getFieldValue('untilAllRedemptionsFilled') === true)
  && (vals[`${vals.discountType}Value`]) && (form.getFieldValue('revBasic') === true
|| form.getFieldValue('revPrivate') === true
|| form.getFieldValue('revPlus') === true)) {
      vals = applyTrim(vals);
      vals.discountValue = vals[`${vals.discountType}Value`];
      let durationType;
      if (vals.durationInHours === true) {
        durationType = 'durationInHours';
      } else if (vals.durationDateRange === true) {
        durationType = 'durationDateRange';
      } else if (vals.untilAllRedemptionsFilled === true) {
        durationType = 'untilAllRedemptionsFilled';
      }
      vals.durationType = durationType;
      vals.durationInHours = vals.durationIn || null;
      vals.maxRedemptions = vals.maxRedemptions === undefined ? 0 : vals.maxRedemptions;
      if (vals.promoCodeType === 'programs') {
        vals.programsPromoApplyDurationValue = vals.programsPromoApplyDurationValue
          ? parseInt(vals.programsPromoApplyDurationValue, 10) : null;
        vals.programs = vals?.programs?.map((p) => p.id);
      }
      vals.additionalAmountOffWithMembership = vals?.additionalAmountOffWithMembership || null;

      if (vals?.redemptionLimitType === 'once') {
        vals.maxRedemptions = 1;
        vals.remainingRedemptions = 1;
      } else if (vals?.redemptionLimitType === 'unlimited') {
        vals.maxRedemptions = 0;
        vals.remainingRedemptions = 0;
      } else {
        vals.remainingRedemptions = vals.maxRedemptions;
      }
      vals.revBasic = vals.revBasic || false;
      vals.revPlus = vals.revPlus || false;
      vals.revPrivate = vals.revPrivate || false;
      vals.isRevDoc = vals.isRevDoc || false;
      vals.isInfluencer = vals.isInfluencer || false;
      vals.procedures = vals?.procedures?.map((x) => ({ ...x, procedureId: x.id })) || [];
      if (vals.durationType === 'durationDateRange') {
        vals.effectiveDate = vals?.effectiveDate === undefined ? moment().format('YYYY-MM-DD') : moment(vals?.effectiveDate).format('YYYY-MM-DD');
        vals.effectiveDate = getStartOfDayStringFromDateString(vals.effectiveDate);
        vals.termDate = vals?.termDate === undefined ? null : getStartOfNextDayFromDateString(moment(vals?.termDate).format('YYYY-MM-DD'));
      } else {
        vals.effectiveDate = vals?.effectiveDate === undefined ? moment().utc().format('YYYY-MM-DDTHH:mm:ss') : moment(vals?.effectiveDate).utc().format('YYYY-MM-DDTHH:mm:ss');
        vals.termDate = vals?.termDate === undefined ? null : moment(vals?.termDate).utc().format('YYYY-MM-DDTHH:mm:ss');
      }
      if (vals.applicableMemberTypes?.includes('pet') === false) {
        vals.petTypes = [];
      }
      vals.showTo = vals?.showTo?.filter((x) => x !== -1);
      vals._status = 'draft';
      if (id === 'new') {
        if (goLive) {
          setGoLive(false);
          vals._status = 'published';
        }
        dispatch(createPromosSetup(vals)).then((payload) => {
          if (_.isEmpty(payload.error)) {
            goBack();
          }
        });
      } else {
        if (goLive) {
          vals._status = 'published';
          if (vals.durationType === 'durationInHours' || vals.durationType === 'untilAllRedemptionsFilled') {
            vals.effectiveDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
            if (vals.durationType === 'durationInHours') {
              vals.termDate = moment().add(vals?.durationInHours, 'h').utc().format('YYYY-MM-DDTHH:mm:ss');
            }
          }
        }
        vals.id = id;
        dispatch(updatePromosSetup({ id, params: vals })).then((payload) => {
          if (_.isEmpty(payload.error)) {
            if (goLive) {
              setGoLive(false);
              toast.success('Promo Code is live');
              goBack();
            }
          }
        });
      }
    } else {
      setError(true);
      toast.error('Please fill the entire form');
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getPromosSetup(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopeied(false);
      }, 500);
    }
  }, [showCopied]);

  useEffect(() => {
    if (!_.isEmpty(selectedPromosSetupSlice)) {
      setImageSrc(null);
      if (id && selectedPromosSetupSlice.promoCodeImage?.url) {
        setImageSrc(selectedPromosSetupSlice.promoCodeImage?.url);
      }

      if (selectedPromosSetupSlice?.isInfluencer
        && selectedPromosSetupSlice?.influencerMemberIds?.length > 0) {
        const filter = selectedPromosSetupSlice?.influencerMemberIds?.reduce((acc, o, i) => {
          acc[`where[revdocId][in][${i}]`] = o.influencerMemberId;
          return acc;
        }, {});

        dispatch(getMembersByID(filter));
      }
      let termDate = null;
      if (selectedPromosSetupSlice?.termDate) {
        if (selectedPromosSetupSlice?.durationType === 'durationDateRange') {
          termDate = moment(selectedPromosSetupSlice?.termDate).subtract(1, 'day').format('YYYY-MM-DD');
        } else {
          termDate = moment(new Date(selectedPromosSetupSlice?.termDate)).format('YYYY-MM-DD');
        }
      }
      form.setFieldsValue({
        ...selectedPromosSetupSlice,
        deliveryMethods: selectedPromosSetupSlice?.deliveryMethods?.map((o) => o.id),
        promoCodeImage: selectedPromosSetupSlice?.promoCodeImage?.id,
        programs: !_.isEmpty(selectedPromosSetupSlice?.programs)
          ? selectedPromosSetupSlice?.programs : null,
        procedures: selectedPromosSetupSlice?.procedures?.map((o) => ({ ...o, ...o?.procedureId })),
        remainingMembers: selectedPromosSetupSlice?.remainingMembers || 0,
        maxRedemptions: selectedPromosSetupSlice?.redemptionLimitType !== 'specificNumber' ? null : selectedPromosSetupSlice?.maxRedemptions || 0,
        effectiveDate: selectedPromosSetupSlice?.effectiveDate ? moment(new Date(selectedPromosSetupSlice?.effectiveDate)).format('YYYY-MM-DD') : null,
        termDate,
      });
      if (selectedPromosSetupSlice?.discountType) {
        const key = `${selectedPromosSetupSlice.discountType}Value`;
        form.setFieldsValue({ [key]: selectedPromosSetupSlice?.discountValue });
      }
      if (selectedPromosSetupSlice?.durationType) {
        const key = `${selectedPromosSetupSlice.durationType}`;
        form.setFieldsValue({ [key]: true });
        if (selectedPromosSetupSlice?.durationType === 'durationInHours') {
          form.setFieldValue('durationIn', selectedPromosSetupSlice?.durationInHours);
        }
      }
      setRefresh(!refresh);
    }
  }, [selectedPromosSetupSlice]);

  const getTitle = () => {
    const selectedPromocodeType = form.getFieldValue('promoCodeType');
    let title = 'Membership Discount *';
    if (selectedPromocodeType !== 'membershipFees') {
      const index = promoCodeTypes?.findIndex((x) => x.value === selectedPromocodeType);
      if (index !== -1) {
        title = promoCodeTypes[index].label?.includes('Off') ? `Amount ${promoCodeTypes[index].label}*` : `Amount Off ${promoCodeTypes[index].label}*`;
      } else {
        title = 'Discount *';
      }
    }
    return title;
  };

  const onChangeValue = (key, val) => {
    let obj = {};
    let objDiscount = {};
    if (key === 'durationInHours') {
      obj = {
        durationDateRange: false,
        untilAllRedemptionsFilled: false,
      };
    } else if (key === 'durationDateRange') {
      obj = {
        durationInHours: false,
        untilAllRedemptionsFilled: false,
        effectiveDate: null,
        termDate: null,
      };
    } else if (key === 'untilAllRedemptionsFilled') {
      obj = {
        durationInHours: false,
        durationDateRange: false,
      };
    }
    if (key === 'isInfluencer') {
      if (!val) {
        form.setFieldsValue({ 'influencerMemberIds': [] });
      } else if (form.getFieldValue('influencerMemberIds')?.length === 0 || form.getFieldValue('influencerMemberIds')?.length === undefined) {
        form.setFieldsValue({ 'influencerMemberIds': [{ 'influencerMemberId': '' }] });
      }
      setRefresh(!refresh);
    }
    if (key === 'isRegionDependent') {
      if (!val) {
        form.setFieldsValue({ 'regions': [] });
      } else if (form.getFieldValue('regions')?.length === 0 || form.getFieldValue('regions')?.length === undefined) {
        form.setFieldsValue({ 'regions': [{ 'stateCode': null, city: '', postalCodes: [{ postalCode: '' }] }] });
      }
      setRefresh(!refresh);
    }
    if (key === 'redemptionLimitType' && val !== 'specificNumber') {
      if (val === 'unlimited' && form.getFieldValue('untilAllRedemptionsFilled') === true) {
        obj = {
          durationInHours: false,
          durationDateRange: false,
          untilAllRedemptionsFilled: false,
        };
      }
    }
    if (key === 'discountType') {
      if (val === 'amountOff') {
        objDiscount = {
          percentageOffValue: null,
          freeMonthsValue: null,
          programsPromoApplyDurationType: null,
          programsPromoApplyDurationValue: null,
        };
      } else if (val === 'percentageOff') {
        objDiscount = {
          amountOffValue: null,
          freeMonthsValue: null,
          programsPromoApplyDurationType: null,
          programsPromoApplyDurationValue: null,
        };
      } else if (val === 'freeMonths') {
        objDiscount = {
          amountOffValue: null,
          percentageOffValue: null,
          programsPromoApplyDurationType: null,
          programsPromoApplyDurationValue: null,
        };
      }
    }

    if (key === 'promoCodeType') {
      form.setFieldsValue({ 'programs': [] });
      form.setFieldsValue({ 'labs': [] });
      form.setFieldsValue({ 'procedures': [] });
      objDiscount = {
        programsPromoApplyDurationType: null,
        programsPromoApplyDurationValue: null,
      };
      setRefresh(!refresh);
    } else if (key === 'additionalAmountOffWithMembership') {
      if (val === false) {
        form.setFieldsValue({ 'additionalAmountOff': null });
      }
    }

    if (key === 'applicableMemberTypes' || key === 'petTypes') {
      if (key === 'applicableMemberTypes' && !val?.includes('pet')) {
        form.setFields([{ name: 'petTypes', value: [] }]);
      }
      if (form.getFieldValue('promoCodeType') === 'programs' && form.getFieldValue('programs')?.length > 0) {
        const programs = form.getFieldValue('programs').filter((o) => {
          const applicableMemberTypes = form.getFieldValue('applicableMemberTypes') || [];
          const petTypes = form.getFieldValue('petTypes') || [];

          // Function to check if all items in one array are contained in another
          const arrayContainsAll = (sourceArray, targetArray) => {
            if (!sourceArray || !targetArray) return false; // Handle undefined/null cases
            return targetArray.every((item) => sourceArray.includes(item));
          };

          // Check if applicableMemberTypes contains all required items
          const hasApplicableMemberType = arrayContainsAll(
            applicableMemberTypes,
            o?.applicableMemberTypes || [],
          );

          // Check if petTypes contains all required items
          const hasPetType = o?.petTypes && arrayContainsAll(petTypes, o.petTypes);

          return hasApplicableMemberType && (petTypes.length > 0 ? hasPetType : true);
        }) || [];

        // Update the form field
        form.setFields([
          { name: 'programs', value: programs },
        ]);

        setRefresh(!refresh);
      } else if (form.getFieldValue('promoCodeType') === 'procedures' && form.getFieldValue('procedures')?.length > 0) {
        const procedures = form.getFieldValue('procedures').filter((o) => {
          const applicableMemberTypes = form.getFieldValue('applicableMemberTypes') || [];
          const petTypes = form.getFieldValue('petTypes') || [];

          // Function to check if all items in one array are contained in another
          const arrayContainsAll = (sourceArray, targetArray) => {
            if (!sourceArray || !targetArray) return false; // Handle undefined/null cases
            return targetArray.every((item) => sourceArray.includes(item));
          };

          // Check if applicableMemberTypes contains all required items
          const hasApplicableMemberType = arrayContainsAll(
            applicableMemberTypes,
            o?.applicableMemberTypes || [],
          );

          // Check if petTypes contains all required items
          const hasPetType = o?.petTypes && arrayContainsAll(petTypes, o.petTypes);

          return hasApplicableMemberType && (petTypes.length > 0 ? hasPetType : true);
        }) || [];

        // Update the form field
        form.setFields([
          { name: 'procedures', value: procedures },
        ]);

        setRefresh(!refresh);
      }
    }

    if (key === 'showTo') {
      const curVals = form.getFieldValue('showTo') || [];
      const index = curVals?.findIndex((x) => x === val);
      if (index && index !== -1) {
        curVals.splice(index, 1);
      } else {
        curVals.push(index);
      }
      form.setFieldsValue({ [key]: curVals });
      setRefresh(!refresh);
    } else {
      form.setFieldsValue({ [key]: val, ...obj, ...objDiscount });
      setRefresh(!refresh);
    }
  };

  const generatePromoCode = () => {
    const possibleValues = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const val = [...Array(10)].reduce((a) => a + possibleValues[Math.trunc((Math.random() * possibleValues.length))], '');
    form.setFieldsValue({ promoCode: val });
    setRefresh(!refresh);
  };

  const programValidation = (m, v, k) => {
    if (form.getFieldValue('promoCodeType') === 'programs' && form.getFieldValue('discountType') === k) {
      return emptyFieldValidator(m, v);
    }
    return Promise.resolve();
  };

  const failSafeEmptyNonZeroFieldValidator = (m, v) => {
    if (v !== null && v !== undefined && v !== '0' && v !== '' && v !== 0) {
      if (typeof (v) === 'string' && !v.trim()) {
        return Promise.reject(m);
      }
      if (typeof (v) === 'object' && _.isEmpty(v)) {
        return Promise.reject(m);
      }
      return Promise.resolve();
    }
    return Promise.reject(m);
  };

  const procedureConditionalValidation = (m, v, index, key) => {
    if (form.getFieldValue(['procedures', index, 'repeatAfterCompletion'])) {
      if (key !== 'promoDurationInHours' && key !== 'promoDollarAmountOff') {
        return emptyFieldValidator(m, v);
      }

      return failSafeEmptyNonZeroFieldValidator(m, v);
    }
    return Promise.resolve();
  };

  const discountTypefailSafeEmptyFieldValidator = (m, v, k) => {
    if (form.getFieldValue('discountType')) {
      if (form.getFieldValue('discountType') === k) {
        return failSafeEmptyNonZeroFieldValidator(m, v);
      }
    }
    return Promise.resolve();
  };

  const additionalDiscountTypefailSafeEmptyFieldValidator = (m, v, k) => {
    if (form.getFieldValue(k)) {
      return failSafeEmptyNonZeroFieldValidator(m, v);
    }
    return Promise.resolve();
  };

  const deliveryMethods = (m, v) => {
    if (form.getFieldValue('promoCodeType') === 'procedures') {
      return emptyFieldValidator(m, v);
    }
    return Promise.resolve();
  };

  const copyToClipboard = () => {
    const copyText = selectedPromosSetupSlice?.adDeepLink;
    const isCopy = copy(copyText);
    if (isCopy) {
      setShowCopeied(isCopy);
    }
  };

  const showPreview = () => {
    setDialogOpen(true);
  };

  const onGoLive = () => {
    setGoLive(true);
    form.submit();
  };

  const deleteLabs = (e, row) => {
    const data = form.getFieldValue('labs');
    if (data && data.length > 0) {
      const index = data?.findIndex((x) => x.id === row.id);
      if (index !== undefined && index !== -1) {
        data.splice(index, 1);
        form.setFieldsValue({ 'labs': data });
        setRefresh(!refresh);
      }
    }
  };

  const deleteProgram = () => {
    form.setFieldsValue({ 'programs': null });
    setRefresh(!refresh);
  };

  const onSelect = (data, option, index) => {
    const memebrata = form.getFieldValue('influencerMemberIds') || [];
    if (memebrata?.length > 0) {
      memebrata[index] = {
        influencerMemberId: option?.revdocId,
        'demographics.firstName': option?.demographics?.firstName || '',
        'demographics.lastName': option?.demographics?.lastName || '',
        mobilePhoneNumber: option?.mobilePhoneNumber || '',
      };
      form.setFieldValue({ 'influencerMemberIds': memebrata });
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    if (membersSelected?.length > 0) {
      form.setFieldsValue({
        'influencerMemberIds': membersSelected?.map((x) => ({
          influencerMemberId: x.revdocId,
          'demographics.firstName': x.demographics?.firstName,
          'demographics.lastName': x.demographics?.lastName,
          'mobilePhoneNumber': x?.mobilePhoneNumber,
        })),
      });
      setRefresh(!refresh);
    }
  }, [membersSelected]);

  const onChange = (data, option, index) => {
    const memebrata = form.getFieldValue('influencerMemberIds') || [];
    if (memebrata?.length > 0) {
      memebrata?.splice(index, 1);
      memebrata.push({
        influencerMemberId: data, 'demographics.firstName': '', 'demographics.lastName': '', mobilePhoneNumber: '',
      });
      form.setFieldValue({ 'influencerMemberIds': memebrata });
      setRefresh(!refresh);
    }
    if (_.isEmpty(option) && data?.length > 6) {
      const params = {
        'where[or][0][revdocId][like]': data,
        'where[or][1][healthRecordId][like]': data,
      };
      dispatch(getMembers(params));
    }
  };

  const completedPromCode = () => {
    let isCompleted = false;
    if (selectedPromosSetupSlice?._status === 'published') {
      if ((selectedPromosSetupSlice?.durationType === 'durationInHours'
        && moment().isSameOrAfter(moment(selectedPromosSetupSlice?.effectiveDate).add(selectedPromosSetupSlice?.durationInHours, 'hours'))
      ) || (selectedPromosSetupSlice?.durationType === 'durationDateRange'
        && moment().isSameOrAfter(moment(selectedPromosSetupSlice?.termDate))
      ) || (selectedPromosSetupSlice?.durationType === 'untilAllRedemptionsFilled'
        && selectedPromosSetupSlice?.remainingRedemptions <= 0
      ) || (selectedPromosSetupSlice?.redemptionLimitType === 'specificNumber' && selectedPromosSetupSlice?.remainingRedemptions <= 0)) {
        isCompleted = true;
      }
    }
    return isCompleted;
  };

  const getDiscountOptions = () => {
    let options = [
      { label: '', value: 'amountOff' },
      { label: '', value: 'percentageOff' },
    ];
    if (form.getFieldValue('promoCodeType') === 'membershipFees') {
      options = [
        { label: '', value: 'amountOff' },
        { label: '', value: 'percentageOff' },
        { label: '', value: 'freeMonths' },
      ];
    }
    return options;
  };

  const getProcedureFilter = () => {
    const filter = {
      'where[isRevPlusSpecials][not_equals]': true,
      'where[isProgramOnlyProcedure][not_equals]': true,
      'where[isRevTogether][not_equals]': true,
    };
    form.getFieldValue('applicableMemberTypes')?.forEach((a, i) => {
      filter[`where[applicableMemberTypes][all][${i}]`] = a;
    });

    if (form.getFieldValue('applicableMemberTypes')?.includes('pet')) {
      form.getFieldValue('petTypes')?.forEach((a, i) => {
        filter[`where[petTypes][all][${i}]`] = a;
      });
    }
    return filter;
  };

  const getProgramFilter = () => {
    const filter = {};
    form.getFieldValue('applicableMemberTypes')?.forEach((a, i) => {
      filter[`where[applicableMemberTypes][all][${i}]`] = a;
    });

    if (form.getFieldValue('applicableMemberTypes')?.includes('pet')) {
      form.getFieldValue('petTypes')?.forEach((a, i) => {
        filter[`where[petTypes][all][${i}]`] = a;
      });
    }
    return filter;
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => { setError(true); toast.error('Please fill the entire form'); }}
      >
        <div className="add-new-specialty-container new-promo">
          {isLoading && 'Loading...'}
          <div className="add-new-specialty-container__wrapper">
            <h1>
              Promo Code
            </h1>
            <div className="section">
              <label className="check-label-promo">
                <div>Type *</div>
              </label>
              <Form.Item
                name="isRevDoc"
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="RevDoc" />
              </Form.Item>
              <Form.Item
                name="isInfluencer"
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Invite Code/Member Code" onChange={(e) => onChangeValue('isInfluencer', e?.target?.checked)} />
              </Form.Item>
              { form.getFieldValue('isInfluencer') === true && (
              <div>
                <label>
                  <div>Who we are running this for:</div>
                </label>
                <Form.List
                  name="influencerMemberIds"
                  rules={[
                    {
                      validator: (m, v) => {
                      // eslint-disable-next-line max-len
                        const duplicate = v
                          ?.map(
                            (o) => v?.filter((b) => o?.influencerMemberId
                              ?.toLowerCase() === b?.influencerMemberId?.toLowerCase()).length,
                          )
                          .find((a) => a > 1);
                        if (duplicate) {
                          return Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: 'Please add unique code',
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((item, index) => (
                        <div className="member-sub-container">
                          <div className="member-auto-search">
                            <Form.Item
                              name={[item.name, 'influencerMemberId']}
                              className="formfield"
                              rules={[
                                {
                                  validator: emptyFieldValidator,
                                  message: 'This field is required',
                                },
                                { min: 6, message: 'This field should have at least 6 characters' },
                                { max: 10, message: 'This Field can not exceed 10 characters' },
                                blackListedChars,
                              ]}
                            >
                              <AutoComplete
                                options={members || []}
                                autoFocus
                                style={{ width: 400 }}
                                filterOption={(inputValue, option) => option.label.toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1}
                                onSelect={(v, o) => onSelect(v, o, item.name)}
                                onChange={(v, o) => onChange(v, o, item.name)}
                                maxLength={10}
                                waitTime={1300}
                                allowClear
                                placeholder="Enter the Member ID"
                              />

                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name={[item.name, 'demographics.firstName']}
                              className="formfield"
                            >
                              <InputField
                                placeholder="First Name"
                                maxLength={10}
                                disabled
                              />

                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name={[item.name, 'demographics.lastName']}
                              className="formfield"
                            >
                              <InputField
                                placeholder="Last Name"
                                maxLength={10}
                                disabled
                              />

                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name={[item.name, 'mobilePhoneNumber']}
                              className="formfield"
                              rules={[{ required: true, message: 'This field is required' }]}
                            >
                              <InputField
                                placeholder="Phone Number"
                                maxLength={10}
                                disabled
                              />

                            </Form.Item>
                          </div>
                          <div onClick={() => remove(index)}><TrashIcon /></div>
                        </div>
                      ))}
                      {
                      form.getFieldError('influencerMemberIds')?.length > 0 && (<div className="promo-error">{form.getFieldError('influencerMemberIds')[0]}</div>)
                    }
                      {
                     form.isFieldTouched('influencerMemberIds') && form.getFieldValue('influencerMemberIds')?.length === 0 && form.getFieldValue('isInfluencer') === true && (<div className="promo-error">Please add atleast one Invite Code/Member Code</div>)
                    }
                      <div className="add-icon" onClick={() => add({ influencerMemberId: '' })}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0.875C4.51992 0.875 0.875 4.51992 0.875 9C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM12.125 9.625H9.625V12.125C9.625 12.2908 9.55915 12.4497 9.44194 12.5669C9.32473 12.6842 9.16576 12.75 9 12.75C8.83424 12.75 8.67527 12.6842 8.55806 12.5669C8.44085 12.4497 8.375 12.2908 8.375 12.125V9.625H5.875C5.70924 9.625 5.55027 9.55915 5.43306 9.44194C5.31585 9.32473 5.25 9.16576 5.25 9C5.25 8.83424 5.31585 8.67527 5.43306 8.55806C5.55027 8.44085 5.70924 8.375 5.875 8.375H8.375V5.875C8.375 5.70924 8.44085 5.55027 8.55806 5.43306C8.67527 5.31585 8.83424 5.25 9 5.25C9.16576 5.25 9.32473 5.31585 9.44194 5.43306C9.55915 5.55027 9.625 5.70924 9.625 5.875V8.375H12.125C12.2908 8.375 12.4497 8.44085 12.5669 8.55806C12.6842 8.67527 12.75 8.83424 12.75 9C12.75 9.16576 12.6842 9.32473 12.5669 9.44194C12.4497 9.55915 12.2908 9.625 12.125 9.625Z" fill="#FF6F33" />
                        </svg>
                        <label>
                          Add Another Member ID
                        </label>
                      </div>
                    </>
                  )}
                </Form.List>
              </div>
              )}
              {
              ((form.isFieldTouched('isInfluencer') || form.isFieldTouched('isRevDoc') || error) && (form.getFieldValue('isInfluencer') === undefined || form.getFieldValue('isInfluencer') === false) && (form.getFieldValue('isRevDoc') === undefined || form.getFieldValue('isRevDoc') === false)) && (<div className="promo-error">This field is required</div>)
            }
            </div>
            <div className="section member-type">
              <Form.Item
                name="applicableMemberTypes"
                className="formfield"
                style={{ display: 'flex' }}
                valuePropName="checked"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <MultiCheckboxSelect
                  label="Audience for Promo *"
                  options={[{ label: 'RevAdults', value: 'normal' },
                    { label: 'RevKids (Under 18)', value: 'dependent' },
                    { label: 'RevPets', value: 'pet' },
                  ]}
                  value={form.getFieldValue('applicableMemberTypes')?.filter((x) => x !== -1) || []}
                  onChange={(e) => onChangeValue('applicableMemberTypes', e)}
                />
              </Form.Item>
              {form.getFieldValue('applicableMemberTypes')?.includes('pet') && (
              <div className="program-type-pet">
                <Form.Item
                  name="petTypes"
                  className="formfield"
                  style={{ display: 'flex' }}
                  valuePropName="checked"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <MultiCheckboxSelect
                    label="Pet Type *"
                    options={[{ label: 'Cats', value: 'cat' },
                      { label: 'Dogs', value: 'dog' },
                    ]}
                    value={form.getFieldValue('petTypes')?.filter((x) => x !== -1) || []}
                    onChange={(e) => onChangeValue('petTypes', e)}
                  />
                </Form.Item>
              </div>
              )}
            </div>
            {((form.getFieldValue('applicableMemberTypes')?.includes('normal') || form.getFieldValue('applicableMemberTypes')?.includes('dependent'))
                  || (form.getFieldValue('applicableMemberTypes')?.includes('pet') && (form.getFieldValue('petTypes')?.includes('dog') || form.getFieldValue('petTypes')?.includes('cat'))))
                  && (
                  <div className="section">
                    <Form.Item
                      name="promoCodeType"
                      className="formfield radio-promo"
                      rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                    >
                      <RadioGroup
                        label="Promo Code Type *"
                        options={promoCodeTypes}
                        onChange={(v) => onChangeValue('promoCodeType', v)}
                      />
                    </Form.Item>
                  </div>
                  )}
            {form.getFieldValue('promoCodeType') && (
            <>
              <div className="section">
                <label>{getTitle()}</label>
                <div className="discount-type-container">
                  <Form.Item
                    name="discountType"
                    className="formfield"
                    rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  >
                    <RadioGroup
                      style={{
                        gap: 57, marginTop: 30, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                      }}
                      options={getDiscountOptions()}
                      onChange={(e) => onChangeValue('discountType', e)}
                    />
                  </Form.Item>
                  <div style={{
                    gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                  }}
                  >

                    <div className="discount-type-sub-container amount-text">
                      <div>
                        <label className="radio-discount-label">Dollar Amount Off</label>
                        <div style={{
                          display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                        }}
                        >
                          $
                          <Form.Item
                            name="amountOffValue"
                            className="formfield"
                            rules={[
                              {
                                validator: (key, val) => discountTypefailSafeEmptyFieldValidator(key, val, 'amountOff'),
                                message: 'This field is required',
                              },
                              allowOnlyNumbers,
                            ]}
                          >
                            <InputField type="number" disabled={form.getFieldValue('discountType') !== 'amountOff'} />
                          </Form.Item>
                        </div>
                      </div>
                      { (form.getFieldValue('promoCodeType') === 'programs' && form.getFieldValue('discountType') === 'amountOff') && (
                      <>
                        <div className="drp-discount">
                          <label>Duration *</label>
                          <div style={{
                            display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                          }}
                          >
                            <Form.Item
                              name="programsPromoApplyDurationType"
                              className="formfield"
                              rules={[{ validator: (k, v) => programValidation(k, v, 'amountOff'), message: 'This field is required' }]}
                            >
                              <DropDown
                                label=""
                                placeholder=""
                                className="biomarker-dropdown"
                                isOptionsObj
                                disabled={form.getFieldValue('discountType') !== 'amountOff'}
                                options={[
                                  { value: 'numberOfMonths', label: 'Number of Month' },
                                  { value: 'untilEndOfProgram', label: 'End of Program' },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {form.getFieldValue('programsPromoApplyDurationType') === 'numberOfMonths' && (
                        <div className="drp-discount">
                          <label>Number of Months</label>
                          <div style={{
                            display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                          }}
                          >
                            <Form.Item
                              name="programsPromoApplyDurationValue"
                              className="formfield program-month"
                              rules={[{ validator: failSafeEmptyNonZeroFieldValidator, message: 'This field is required' }]}
                            >
                              <InputField type="number" disabled={form.getFieldValue('discountType') !== 'amountOff'} />
                            </Form.Item>
                            <span className="member-label">months</span>
                          </div>
                        </div>
                        )}
                      </>
                      )}
                    </div>
                    <div className="discount-type-sub-container amount-text amount-text-left">
                      <div>
                        <label className="radio-discount-label">Percentage Off</label>
                        <div style={{
                          display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                        }}
                        >
                          <Form.Item
                            name="percentageOffValue"
                            className="formfield"
                            rules={[
                              {
                                validator: (key, val) => discountTypefailSafeEmptyFieldValidator(key, val, 'percentageOff'),
                                message: 'This field is required',
                              },
                              allowDecimalNumbers,
                            ]}
                          >
                            <InputField type="number" disabled={form.getFieldValue('discountType') !== 'percentageOff'} />
                          </Form.Item>
                          %
                        </div>
                      </div>
                      { (form.getFieldValue('promoCodeType') === 'programs' && form.getFieldValue('discountType') === 'percentageOff') && (
                      <>
                        <div className="drp-discount">
                          <label>Duration *</label>
                          <div style={{
                            display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                          }}
                          >
                            <Form.Item
                              name="programsPromoApplyDurationType"
                              className="formfield"
                              rules={[{ validator: (k, v) => programValidation(k, v, 'percentageOff'), message: 'This field is required' }]}
                            >
                              <DropDown
                                label=""
                                placeholder=""
                                className="biomarker-dropdown"
                                isOptionsObj
                                disabled={form.getFieldValue('discountType') !== 'percentageOff'}
                                options={[
                                  { value: 'numberOfMonths', label: 'Number of Month' },
                                  { value: 'untilEndOfProgram', label: 'End of Program' },
                                ]}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {form.getFieldValue('programsPromoApplyDurationType') === 'numberOfMonths' && (
                        <div className="drp-discount">
                          <label>Number of Months</label>
                          <div style={{
                            display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                          }}
                          >
                            <Form.Item
                              name="programsPromoApplyDurationValue"
                              className="formfield program-month"
                              rules={[{ validator: failSafeEmptyNonZeroFieldValidator, message: 'This field is required' }]}
                            >
                              <InputField type="number" disabled={form.getFieldValue('discountType') !== 'percentageOff'} />
                            </Form.Item>
                            <span className="member-label">months</span>
                          </div>
                        </div>
                        )}
                      </>
                      )}
                    </div>
                    { (form.getFieldValue('promoCodeType') === 'membershipFees') && (
                    <div>
                      <div className="discount-type-sub-container amount-text amount-text-left">
                        <div>
                          <label className="radio-discount-label">Months Free</label>
                          <div style={{
                            display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                          }}
                          >
                            <Form.Item
                              name="freeMonthsValue"
                              className="formfield"
                              rules={[
                                {
                                  validator: (key, val) => discountTypefailSafeEmptyFieldValidator(key, val, 'freeMonths'),
                                  message: 'This field is required',
                                },
                                allowOnlyNumbers,
                              ]}
                            >
                              <InputField type="number" disabled={form.getFieldValue('discountType') !== 'freeMonths'} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                {
                      form.getFieldValue('promoCodeType') === 'membershipFees' && (
                        <div className="discount-type-sub-container amount-text">
                          <div>
                            <label>
                              do you want additional promo on the procedure?
                            </label>
                            <div style={{
                              display: 'flex', gap: 8, alignItems: 'center', fontSize: 17,
                            }}
                            >
                              <Form.Item
                                name="additionalAmountOffWithMembership"
                                style={{ display: 'flex' }}
                                valuePropName="checked"
                              >
                                <CheckBox label="" onChange={(e) => onChangeValue('additionalAmountOffWithMembership', e?.target?.checked)} />
                              </Form.Item>
                              <span>$</span>
                              <Form.Item
                                name="additionalAmountOff"
                                className="formfield"
                                rules={[
                                  {
                                    validator: (key, val) => additionalDiscountTypefailSafeEmptyFieldValidator(key, val, 'additionalAmountOffWithMembership'),
                                    message: 'This field is required',
                                  },
                                  allowOnlyNumbers,
                                ]}
                              >
                                <InputField type="number" disabled={form.getFieldValue('additionalAmountOffWithMembership') !== true} />
                              </Form.Item>
                              <span className="member-label">off</span>
                            </div>
                          </div>
                        </div>
                      )
                    }
              </div>
              {
            form.getFieldValue('promoCodeType') === 'procedures' && (
            <div className="section">
              <LinkedProcedure
                showGrid={false}
                value={form.getFieldValue('procedures') || []}
                onChange={(v) => {
                  const data = v?.map((o) => ({
                    ...o,
                    repeatAfterCompletion: o.repeatAfterCompletion || false,
                    promoDurationInHours: o.promoDurationInHours || null,
                    promoDollarAmountOff: o.promoDollarAmountOff || null,
                    promoMessage: o.promoMessage || null,
                  }));
                  onChangeValue('procedures', data);
                }}
                multiple
                key={1}
                tag="Procedure"
                filter={getProcedureFilter()}
              />

              <Form.List
                name="procedures"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                {(fields, { remove }) => (
                  <div className="procedure-outer">
                    {fields.map((item, index) => (
                      <div className="sub-container procedure-sub-container">
                        <div className="procedure-item">
                          <div className="items-outer">
                            <div className="section">
                              <span>{form.getFieldValue(['procedures', item.name, 'procedureName'])}</span>
                            </div>
                            <div className="section">
                              <label>Repeat Promo after Encounter Completion?</label>
                              <Form.Item valuePropName="checked" name={[item.name, 'repeatAfterCompletion']} noStyle>
                                <Switch checkedChildren="Yes" className={`${form.getFieldValue(['procedures', item.name, 'repeatAfterCompletion']) === true ? 'active' : 'inactive'}`} unCheckedChildren="No" />
                              </Form.Item>
                            </div>
                            <div className="section drp-discount">
                              <label>How long will this follow-up code last?</label>
                              <Form.Item
                                name={[item.name, 'promoDurationInHours']}
                                className="formfield align-right"
                                rules={[{ validator: (k, v) => procedureConditionalValidation(k, v, item.name, 'promoDurationInHours'), message: 'This field is required' }]}
                              >
                                <DropDown
                                  label=""
                                  placeholder=""
                                  className="biomarker-dropdown"
                                  isOptionsObj
                                  options={hoursOfDay}
                                />
                              </Form.Item>
                            </div>
                            <div className="section amount-text">
                              <label>Promo Dollar Amount Off</label>
                              <div className="proced-dollar">
                                <span>$</span>
                                <Form.Item
                                  name={[item.name, 'promoDollarAmountOff']}
                                  className="formfield align-right"
                                  rules={[
                                    {
                                      validator: (k, v) => procedureConditionalValidation(k, v, item.name, 'promoDollarAmountOff'),
                                      message: 'This field is required',
                                    },
                                    allowOnlyNumbers,
                                  ]}
                                >
                                  <InputField type="number" />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="last-section">
                              <Form.Item
                                name={[item.name, 'promoMessage']}
                                rules={[{ validator: (k, v) => procedureConditionalValidation(k, v, item.name, 'promoMessage'), message: 'This field is required' }, allowOnlyANSI]}
                              >
                                <TextAreaField
                                  label="Message sent to user *"
                                  placeholder="Message sent to user"
                                  rows={3}
                                  maxLength={160}
                                  className="formfield"
                                />
                              </Form.Item>
                              <div className="text-length">
                                {form.getFieldValue(['procedures', item.name, 'promoMessage'])?.length || 0}
                                /160
                              </div>
                            </div>
                          </div>
                          <div onClick={() => remove(index)}><TrashIcon /></div>

                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Form.List>
              {
              ((form.isFieldTouched('procedures') || error) && (form.getFieldValue('procedures')?.length === undefined || form.getFieldValue('procedures')?.length === 0)) && (<div className="promo-error">This field is required</div>)
            }
            </div>
            )
          }
              {
            form.getFieldValue('promoCodeType') === 'programs' && (
            <div className="section">
              <Form.Item
                name="programs"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <LinkedProcedure
                  showGrid={!_.isEmpty(form.getFieldValue('programs'))}
                  value={form.getFieldValue('programs') || []}
                  onChange={(v) => {
                    onChangeValue('programs', v);
                  }}
                  procedureActionClickHanlder={deleteProgram}
                  multiple
                  tag="Program"
                  key={3}
                  filter={getProgramFilter()}
                />
              </Form.Item>
            </div>
            )
          }

              {
            form.getFieldValue('promoCodeType') === 'labs' && (
            <div className="section">
              <Form.Item
                name="labs"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <LinkedProcedure
                  showGrid={form.getFieldValue('labs')?.length > 0}
                  value={form.getFieldValue('labs') || []}
                  onChange={(v) => {
                    onChangeValue('labs', v);
                  }}
                  procedureActionClickHanlder={deleteLabs}
                  multiple
                  tag="Lab"
                  key={2}
                />
              </Form.Item>
            </div>
            )
          }
            </>
            )}
            <div className="section">

              <label className="check-label-promo">
                <div>What Membership level will this promo code apply to? *</div>
              </label>
              <Form.Item
                name="revBasic"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REV1" />
              </Form.Item>
              <Form.Item
                name="revPlus"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REV+" />
              </Form.Item>
              <Form.Item
                name="revPrivate"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REVPRIVATE" />
              </Form.Item>
              {
                ((form.isFieldTouched('revBasic') || form.isFieldTouched('revPlus') || form.isFieldTouched('revPrivate') || error)
                && (form.getFieldValue('revBasic') === undefined || form.getFieldValue('revBasic') === false)
                && (form.getFieldValue('revPrivate') === undefined || form.getFieldValue('revPrivate') === false)
                && (form.getFieldValue('revPlus') === undefined || form.getFieldValue('revPlus') === false)
                && (<div className="promo-error">This field is required</div>))
              }
            </div>
            <div className="section">
              <Form.Item
                name="showTo"
                style={{ display: 'flex' }}
                valuePropName="checked"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <MultiCheckboxSelect
                  label="Who all will see this promo code? *"
                  options={[{ label: 'REV1', value: 'revBasic' },
                    { label: 'REV+', value: 'revPlus' },
                    { label: 'REVPRIVATE', value: 'revPrivate' },
                  ]}
                  value={form.getFieldValue('showTo')?.filter((x) => x !== -1) || []}
                  onChange={(val) => onChangeValue('showTo', val)}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="userType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioGroup
                  label="User Type *"
                  options={[
                    { label: 'All Users', value: 'allUsers' },
                    { label: 'No Purchase History', value: 'noPurchaseHistory' },
                    { label: 'Made Purchase Before', value: 'madePurchaseBefore' },
                    { label: 'Renewal Date', value: 'renewalDate', disabled: true },
                    { label: 'Anniversary Only', value: 'anniversaryOnly', disabled: true },
                    { label: 'Birthday', value: 'birthDay', disabled: true },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="promoCode"
                rules={[{ required: true, message: 'This field is required' },
                  { min: 6, message: 'This field should have at least 6 characters' },
                  { max: 10, message: 'This Field can not exceed 10 characters' },
                ]}
              >
                <InputField
                  label="Promo Code (This promo code should not have been used before) *"
                  placeholder="Promo Code"
                  className="formfield"
                  maxLength={10}
                  minLength={6}
                />
              </Form.Item>
              <label className="seprator-or">OR</label>
              <div>
                <Button variant={Button.variant.filled} onClick={() => generatePromoCode()}>
                  Generate Promo Code
                </Button>
              </div>
            </div>
            <div className="section">
              <Form.Item
                name="promoTitle"
                rules={[{ required: true, message: 'This field is required' },
                  { max: 40, message: 'This Field can not exceed 40 characters' },
                ]}
              >
                <InputField
                  label="Title of Promo (Users will see this language in the app) *"
                  placeholder="Title of Promo"
                  className="formfield"
                  maxLength={40}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="description"
                rules={[{ required: true, message: 'This field is required' }, allowOnlyANSI]}
              >
                <TextAreaField
                  label="Promo Description *"
                  placeholder="Promo Description"
                  rows={3}
                  maxLength={1500}
                  className="formfield"
                />
              </Form.Item>
              <div className="text-length">
                {form.getFieldValue('description')?.length || 0}
                /1500
              </div>
            </div>
            <div className="section" style={{ flexDirection: 'column' }}>
              <label>Upload Photo to be displayed on Promo *</label>
              <Form.Item
                name="promoCodeImage"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <UploadButton
                  accept=".jpg, .jpeg, .png"
                  placeholder="Upload Photo"
                  onChange={(e) => setImageSrc(URL.createObjectURL(e))}
                />
              </Form.Item>
              {imageSrc && (
              <Image
                className="promo-image"
                width={200}
                src={imageSrc}
              />
              )}
            </div>
            <div className="section">
              <div style={{ display: 'flex', gap: 72 }}>
                <Form.Item
                  name="isRegionDependent"
                  className="formfield radio-promo"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <RadioGroup
                    label="Region Dependent? *"
                    onChange={(e) => onChangeValue('isRegionDependent', e)}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No (Applies to all regions)', value: false },
                    ]}
                  />
                </Form.Item>

                { form.getFieldValue('isRegionDependent') === true && (
                <Form.List
                  name="regions"
                  rules={[]}
                >
                  {(fields, { add, remove }) => (
                    <div className="promo-region">
                      {fields.map((item, index) => (

                        <div className="sub-container-region">
                          <Form.Item
                            name={[item.name, 'stateCode']}
                            className="formfield"
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <StatesDropDown
                              value={form.getFieldValue([item.name, 'stateCode'])}
                              placeholder="State"
                            />

                          </Form.Item>
                          <Form.Item
                            name={[item.name, 'city']}
                            rules={[{ required: true, message: 'This field is required' }]}
                          >
                            <InputField
                              placeholder="City"
                              className="formfield"
                              maxLength={100}
                            />
                          </Form.Item>
                          <Form.List
                            name={[item.name, 'postalCodes']}
                            rules={[]}
                          >
                            {(fieldsPostal) => (
                              <>
                                {fieldsPostal.map((item2) => (

                                  <Form.Item
                                    name={[item2.name, 'postalCode']}
                                    rules={[{ required: true, message: 'This field is required' }]}
                                  >
                                    <InputField
                                      placeholder="Zip"
                                      className="formfield"
                                      maxLength={10}
                                    />
                                  </Form.Item>
                                ))}
                              </>
                            )}
                          </Form.List>
                          <div onClick={() => remove(index)}><TrashIcon /></div>
                        </div>
                      ))}
                      <div
                        className="add-icon"
                        onClick={() => {
                          add({ postalCodes: [{ postalCode: '', stateCode: '', city: '' }] });
                          setRefresh(!refresh);
                        }}
                      >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 0.875C4.51992 0.875 0.875 4.51992 0.875 9C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM12.125 9.625H9.625V12.125C9.625 12.2908 9.55915 12.4497 9.44194 12.5669C9.32473 12.6842 9.16576 12.75 9 12.75C8.83424 12.75 8.67527 12.6842 8.55806 12.5669C8.44085 12.4497 8.375 12.2908 8.375 12.125V9.625H5.875C5.70924 9.625 5.55027 9.55915 5.43306 9.44194C5.31585 9.32473 5.25 9.16576 5.25 9C5.25 8.83424 5.31585 8.67527 5.43306 8.55806C5.55027 8.44085 5.70924 8.375 5.875 8.375H8.375V5.875C8.375 5.70924 8.44085 5.55027 8.55806 5.43306C8.67527 5.31585 8.83424 5.25 9 5.25C9.16576 5.25 9.32473 5.31585 9.44194 5.43306C9.55915 5.55027 9.625 5.70924 9.625 5.875V8.375H12.125C12.2908 8.375 12.4497 8.44085 12.5669 8.55806C12.6842 8.67527 12.75 8.83424 12.75 9C12.75 9.16576 12.6842 9.32473 12.5669 9.44194C12.4497 9.55915 12.2908 9.625 12.125 9.625Z" fill="#FF6F33" />
                        </svg>
                        <label>
                          Add Another Region
                        </label>
                      </div>
                    </div>
                  )}
                </Form.List>
                )}
                {form.getFieldValue('isRegionDependent') === true && error && (form.getFieldValue('regions')?.length === 0 || form.getFieldValue('regions')?.length === undefined) && (<div className="promo-error">Please add atleast one Region</div>) }
              </div>
            </div>
            <div className="section" style={{ flexDirection: 'column' }}>
              <Form.Item
                name="deliveryMethods"
                className="formfield"
                rules={[{ validator: deliveryMethods, message: 'This field is required' }]}
              >
                <DeliveryMethods disabled={!(form.getFieldValue('promoCodeType') === 'procedures')} label={`What delivery methods will this promo code apply to? ${form.getFieldValue('promoCodeType') === 'procedures' ? ' *' : ''}`} />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="isStackable"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioGroup
                  label="Can multiple promos be used with this code? *"
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="section">
              <div>
                <label>How many times can this promo be redeemed? *</label>
              </div>
              <Form.Item
                name="redemptionLimitType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioButton
                  className="procedure-radio"
                  label="Once"
                  checked={form.getFieldValue('redemptionLimitType') === 'once'}
                  value="once"
                  onChange={(e) => onChangeValue('redemptionLimitType', e)}
                />
                <RadioButton
                  className="procedure-radio"
                  label="Specific Number"
                  checked={form.getFieldValue('redemptionLimitType') === 'specificNumber'}
                  value="specificNumber"
                  onChange={(e) => onChangeValue('redemptionLimitType', e)}
                />
                { form.getFieldValue('redemptionLimitType') === 'specificNumber' && (
                <div className="amount-text">
                  <Form.Item
                    name="maxRedemptions"
                    rules={[
                      {
                        validator: failSafeEmptyNonZeroFieldValidator,
                        message: 'This field is required',
                      },
                      allowOnlyNumbers,
                    ]}
                  >
                    <InputField
                      placeholder="#"
                      type="number"
                      className="formfield"
                    />
                  </Form.Item>
                  <span className="member-label">times</span>
                </div>
                )}
                <RadioButton
                  className="procedure-radio"
                  label="Unlimited"
                  checked={form.getFieldValue('redemptionLimitType') === 'unlimited'}
                  value="unlimited"
                  onChange={(e) => onChangeValue('redemptionLimitType', e)}
                />
              </Form.Item>
            </div>
            <div className="section">
              <div>
                <label>How long will this promotion last? *</label>
              </div>
              <Form.Item
                name="durationInHours"
                className="formfield radio-promo"
                rules={[]}
                style={{ display: 'flex' }}
              >
                <RadioButton
                  name="durationInHours"
                  className="procedure-radio"
                  label="A set amount of time (eg: 24 hours)"
                  checked={form.getFieldValue('durationInHours') === true}
                  onChange={() => onChangeValue('durationInHours', true)}
                />
              </Form.Item>
              {
              form.getFieldValue('durationInHours') === true && (
                <div className="amount-text">
                  <Form.Item
                    name="durationIn"
                    rules={[
                      {
                        validator: failSafeEmptyNonZeroFieldValidator,
                        message: 'This field is required',
                      },
                      allowOnlyNumbers,
                    ]}
                  >
                    <InputField
                      placeholder=""
                      type="number"
                      className="formfield"
                    />
                  </Form.Item>
                  <span className="member-label">hours</span>
                </div>
              )
            }
              <Form.Item
                name="durationDateRange"
                className="formfield radio-promo"
                rules={[]}
                style={{ display: 'flex' }}
              >
                <RadioButton
                  name="durationInHours"
                  className="procedure-radio"
                  label="Until a termination date"
                  checked={form.getFieldValue('durationDateRange') === true}
                  onChange={() => onChangeValue('durationDateRange', true)}
                />
              </Form.Item>
              {
              form.getFieldValue('durationDateRange') === true && (
              <div className="promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Promo Effective Date *"
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                  />
                </Form.Item>

                <Form.Item
                  name="termDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <DatePicker
                    label="Promo Term Date"
                    disabledDate={(d) => (form.getFieldValue('effectiveDate') ? moment(d).isBefore(moment(form.getFieldValue('effectiveDate'))) : !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      ))}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
              )
            }
              <Form.Item
                name="untilAllRedemptionsFilled"
                className="formfield radio-promo"
                rules={[]}
                style={{ display: 'flex' }}
              >
                <RadioButton
                  RadioGroup="durationInHours"
                  className="procedure-radio"
                  label="Until all the redemptions are filled"
                  checked={form.getFieldValue('redemptionLimitType') !== 'unlimited' && form.getFieldValue('untilAllRedemptionsFilled') === true}
                  onChange={() => onChangeValue('untilAllRedemptionsFilled', true)}
                  disabled={form.getFieldValue('redemptionLimitType') === 'unlimited'}
                />
              </Form.Item>
              {
              ((form.isFieldTouched('durationInHours') || form.isFieldTouched('untilAllRedemptionsFilled') || form.isFieldTouched('durationDateRange') || error)
              && (form.getFieldValue('durationInHours') === undefined || form.getFieldValue('durationInHours') === false)
              && (form.getFieldValue('durationDateRange') === undefined || form.getFieldValue('durationDateRange') === false)
              && (form.getFieldValue('untilAllRedemptionsFilled') === undefined || form.getFieldValue('untilAllRedemptionsFilled') === false)
              ) && (<div className="promo-error">This field is required</div>)
            }
            </div>
            <div className="section">
              <div className="action-btn-wrapper">
                <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
                {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated:</span>
                  <span className="orange">{moment(selectedPromosSetupSlice.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
                </div>
                )}

                <div className="btn-promo-outer">
                  {!completedPromCode() && ((selectedPromosSetupSlice?._status === 'draft' && selectedPromosSetupSlice?.cancelled !== true) || isNew(id)) && (
                  <>
                    <Button variant={Button.variant.filled} onClick={() => setGoLive(false)} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
                    <Button variant={Button.variant.filled} onClick={showPreview}>Preview</Button>
                    <Button className="green-button" onClick={() => onGoLive()} variant={Button.variant.filled}>Go Live</Button>
                  </>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </Form>
      <DialogPopup
        className="add-preview-dialog"
        open={dialogOpen}
        title="Preview Promo"
        handleOk={() => {}}
        handleCancel={() => setDialogOpen(false)}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <PreviewPromo copyToClipboard={copyToClipboard} showCopied={showCopied} id={id} data={form.getFieldsValue()} showButtons={((selectedPromosSetupSlice?._status === 'draft' && selectedPromosSetupSlice?.cancelled !== true) || isNew(id))} onCancelDialogPopup={() => setDialogOpen(false)} onConfirmSave={() => { form.submit(); setDialogOpen(false); }} />
      </DialogPopup>
    </>
  );
}

export const PROCEDURE_IMAGE = 'Procedure Image';
export const PROCEDURE_PDF = 'Procedure PDF';
export const PROCEDURE_VIDEO = 'Procedure Video';
export const PROFILE_PHOTO = 'Profile Photo';
export const PROVIDER_PHOTO = 'Provider Photo';
export const PROVIDER_VIDEO = 'Provider Video';
export const SUPPLY_IMAGE = 'Supply Image';
export const STAFF_PHOTO = 'Staff Photo';
export const PRM_PHOTO = 'PRM Photo';
export const STAFF_VIDEO = 'Staff Video';
export const PRM_VIDEO = 'PRM Video';
export const AT_HOME_LAB_IMAGE = 'At-Home Lab Image';
export const AT_HOME_LAB_PDF = 'At-Home Lab PDF';
export const AT_HOME_LAB_VIDEO = 'At-Home Lab Video';
export const HOW_TO_VIDEO = 'How-To Video';
export const PROMOCODE_IMAGE = 'Promo Code Image';
export const PROGRAM_IMAGE = 'Program Image';
export const COLLEGE_IMAGE = 'Affiliation Image';
export const CHARITY_LOGO = 'Charity Profile Photo';
export const COLLEGE_COVER_IMAGE = 'Affiliation Cover Photo';
export const GIVEAWAY_IMAGE = 'Giveaway Image';
export const SINGLE_CARD_IMAGE = 'Single Card Image';
export const CAROUSEL_IMAGE = 'Carousel Image';
export const HOME_SCREEN_ICON_IMAGE = 'Home Screen Icon Image';

export const PROVIDER_STATUS = [
  {
    label: 'Registered (Background check pending)',
    value: 'registered',
  },
  {
    label: 'Needs Supervisor (NP/PAs only)',
    value: 'needs-supervisor',
  },
  {
    label: 'Pending PRM Review (PRM to complete checklist)',
    value: 'prm-review',
  },
  {
    label: 'Active (Live on app/available to see patients)',
    value: 'active',
  },
  {
    label: 'Inactive (Provider inactivated themselves)',
    value: 'inactive',
  },
  {
    label: 'Terminated (Provider profile closed by PRM)',
    value: 'terminated',
  },
];

export const DELIVERY_METHODS = {
  'facility': 'Facility',
  'van': 'Van Call',
  'house-call': 'House Call',
  'telemedicine': 'Telemedicine',
  'text-messaging': 'Messaging',
};

export const DELIVERY_GPL_METHODS = {
  'facility': 'Facility',
  'van': 'Van Call',
  'house_call': 'House Call',
  'telemedicine': 'Telemedicine',
  'text_messaging': 'Messaging',
};

export const DELIVERY_METHODS_APPOINTMENT = {
  'facility': { value: 'facilityQuantity', label: 'Facility', price: 'facilityPrice' },
  'van': { value: 'luxeMobileClinicsQuantity', label: 'Van Call', price: 'luxeMobileClinicsPrice' },
  'house-call': { value: 'houseCallQuantity', label: 'House Call', price: 'houseCallPrice' },
  'telemedicine': { value: 'telemedicineQuantity', label: 'Telemedicine', price: 'telemedicinePrice' },
  'text-messaging': { value: 'messagingQuantity', label: 'Messaging', price: 'messagingPrice' },
};

export const hardcodedOptions = [
  { type: 'hardcoded-option', id: 'rev-plus', name: 'REV+ Saves You 40%' },
  { type: 'hardcoded-option', id: 'rev-cash', name: 'RevCash Never Expires' },
  { type: 'hardcoded-option', id: 'order-labs', name: 'Learn how to order labs' },
  { type: 'hardcoded-option', id: 'invite-friends', name: 'Invite Friends, Earn Cash' },
  { type: 'hardcoded-option', id: 'doctor-at-your-doorstep', name: 'Doctor at your Doorstep' },
  { type: 'hardcoded-option', id: 'search-for-housecall', name: 'Search For a House Call' },
  { type: 'hardcoded-option', id: 'safety-and-privacy', name: 'Safety & Privacy' },
  { type: 'hardcoded-option', id: 'rev-private', name: 'Explore REVPRIVATE' },
  { type: 'hardcoded-option', id: 'rev-kids', name: 'RevKids' },
  { type: 'hardcoded-option', id: 'rev-pets', name: 'RevPets' },
];
export const hardcodedKidOptions = [
  { type: 'hardcoded-option', id: 'rev-plus', name: 'REV+ Saves You 40%' },
  { type: 'hardcoded-option', id: 'rev-cash', name: 'RevCash Never Expires' },
  { type: 'hardcoded-option', id: 'order-labs', name: 'Learn how to order labs' },
  { type: 'hardcoded-option', id: 'invite-friends', name: 'Invite Friends, Earn Cash' },
  { type: 'hardcoded-option', id: 'doctor-at-your-doorstep', name: 'Doctor at your Doorstep' },
  { type: 'hardcoded-option', id: 'search-for-housecall', name: 'Search For a House Call' },
  { type: 'hardcoded-option', id: 'safety-and-privacy', name: 'Safety & Privacy' },
  { type: 'hardcoded-option', id: 'rev-private', name: 'Explore REVPRIVATE' },
];
export const hardcodedPetOptions = [
  { type: 'hardcoded-option', id: 'rev-plus', name: 'REV+ Saves You 40%' },
  { type: 'hardcoded-option', id: 'rev-cash', name: 'RevCash Never Expires' },
  { type: 'hardcoded-option', id: 'order-labs', name: 'Learn how to order labs' },
  { type: 'hardcoded-option', id: 'invite-friends', name: 'Invite Friends, Earn Cash' },
  { type: 'hardcoded-option', id: 'doctor-at-your-doorstep', name: 'Doctor at your Doorstep' },
  { type: 'hardcoded-option', id: 'search-for-housecall', name: 'Search For a House Call' },
  { type: 'hardcoded-option', id: 'safety-and-privacy', name: 'Safety & Privacy' },
  { type: 'hardcoded-option', id: 'rev-private', name: 'Explore REVPRIVATE' },
  { type: 'hardcoded-option', id: 'pet-match', name: 'Pet Match' },
  { type: 'hardcoded-option', id: 'pet-diet', name: 'Pet Diet' },
];

export const USER_TYPE = {
  user: 1,
  provider: 2,
  prm: 3,
};

export const READ_STATUS = [
  {
    label: 'Read',
    value: true,
  },
  {
    label: 'Unread',
    value: false,
  },
];

export const MemberConfigKeys = {
  procedureCategory: [
    'id',
    'procedureCategoryName',
  ],
  procedure: [
    'deliveryMethods',
    'id',
    'isRevPlusSpecials',
    'isRevTogether',
    'materialMarkup',
    'materialPrice',
    'memberAppFee',
    'pharmaceuticalMarkup',
    'pharmaceuticalPrice',
    'procedureCategory',
    'procedureImage',
    'procedureName',
    'procedurePrice',
    'providerDeliveryFee',
  ],
  program: [
    'createdByType',
    'deepLink',
    'finalBasicMembershipPrice',
    'finalPlusMembershipPrice',
    'id',
    'procedureCategory',
    'programDurationInMonths',
    'programImage',
    'programName',
  ],
  lab: ['id', 'labImage', 'labName', 'labPrice', 'plusMembershipAppFee'],
  giveaway: ['giveawayImages', 'giveawayName', 'id'],
};

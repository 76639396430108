import React, { useEffect, useState } from 'react';

import './_petDiet.scss';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { PetDietGridColumns } from 'utils/grid/columns';
import { createPetDiets, downlaodPetDiets, listPetDiets } from 'redux-store/petDiets/petDietsActions';
import { clearData, clearError } from 'redux-store/petDiets/petDietsSlice';

export default function PetDietList() {
  const searchKey = 'where[dietName][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [searchKey]: '',
  };
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [addNew, setAddNew] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileName, setFileName] = useState('');
  const [uploadData, setUploadData] = useState([]);
  const dispatch = useDispatch();
  const { petDiet, uploadStatus } = useSelector((state) => state.petDiet);
  const onSearch = (v) => {
    setParams({
      limit: 10,
      page: 1,
      [searchKey]: v,
    });
  };

  const onCancel = (flag) => {
    setAddNew(flag);
    setFileName('');
    setUploadData([]);
    dispatch(clearError());
  };

  useEffect(() => {
    dispatch(listPetDiets(params));
    return () => dispatch(clearData());
  }, [params]);

  const downlaodFile = () => {
    dispatch(downlaodPetDiets());
  };

  const parseCSV = (csvText) => {
    const rows = csvText.split('\n').map((row) => row.trim());
    const data = rows.slice(1).map((row) => {
      if (row?.trim()) {
        return row.split(',')?.length === 3 ? row : undefined;
      }
      return undefined;
    }).filter((x) => x !== undefined);
    if (data?.length > 0) {
      data.unshift('Diet Name,Pet Type,Diet or Medication');
      setUploadData(data);
    }
  };

  const uploadFile = () => {
    if (uploadData?.length > 0) {
      Promise.resolve(dispatch(createPetDiets({ csvString: uploadData.join('\n') }))).then(
        (data) => {
          dispatch(listPetDiets(params));
          if (data?.payload?.uploadStatus?.validationErrors?.length === 0) {
            onCancel(false);
          }
        },
      );
    } else {
      setFileError(true);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileError(false);

    setFileName(event.target.files[0].name);
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      parseCSV(content);
    };
    reader.readAsText(file);
  };

  return (
    <div className="revdoc-setup-container pet-diet">
      <div className="revdoc-setup-container__header">
        <SearchBox placeholder="Search Diet Name " onChange={onSearch} />

        <div className="buttons">
          <Button variant={Button.variant.filled} onClick={() => onCancel(true)}>
            + Upload Pet Diet List
          </Button>
          <Button variant={Button.variant.filled} onClick={() => downlaodFile()}>
            Download Pet Diet List
          </Button>
        </div>
      </div>

      <span className="pet-diet-heading">Pet Diets</span>
      <div className="pet-grid">
        <Grid
          columns={PetDietGridColumns()}
          data={petDiet?.docs || []}
          pagination={{
            total: petDiet.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      {addNew && (
        <DialogPopup
          className="file-upload-popup"
          open={addNew}
          title="Upload Pet Diet"
          handleCancel={() => onCancel()}
          cancelBtn="Close"
        >
          <span className="pet-diet-heading">Upload .csv *</span>
          <div>
            {/* Hidden File Input */}

            <input
              type="file"
              id="file-upload"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />

            {/* Styled Label as Button */}
            <label
              htmlFor="file-upload"
              className="upload-button"
            >
              Upload CSV
            </label>

            {/* Display File Name After Selection */}
            {fileName && (
            <p style={{ marginTop: '10px' }}>
              Selected File:
              {fileName}
            </p>
            )}
          </div>
            {
                fileError && <span className="file-error">Invalid File</span>
            }
            {
                uploadStatus && (
                <div className="summary">
                  <span className="pet-diet-heading">Upload Summary</span>
                  <div>
                    <span>Uploaded record count</span>
                    :
                    {' '}
                    <span>{uploadStatus?.uploadStatus?.insertedRecordCount}</span>
                  </div>
                  <div>
                    <span>Failed record count</span>
                    :
                    {' '}
                    <span>{uploadStatus?.uploadStatus?.failedRecordCount}</span>
                  </div>
                  <div><span>Errors:</span></div>
                  {uploadStatus?.uploadStatus?.validationErrors?.map((e) => (
                    <div>
                      <span>{e?.message}</span>
                      :
                      <span>{e?.row}</span>
                    </div>
                  ))}
                </div>
                )
            }
          <div className="popup-button">
            <Button variant={Button.variant.outlined} onClick={() => onCancel(false)}>
              Cancel
            </Button>
            <Button variant={Button.variant.filled} onClick={() => uploadFile()}>
              Save
            </Button>
          </div>
          <div />
        </DialogPopup>
      )}
    </div>
  );
}

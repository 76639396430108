export const LOGIN_URL = '/auth/login';
export const SIGNUP_URL = '/auth/signup';
export const OTP_URL = '/auth/otp-verify';
export const RESET_PASSWORD_URL = '/auth/reset-password';

export const DASHBOARD_URL = '/dashboard';
export const PROCEDURE_MGMT_URL = `${DASHBOARD_URL}/procedure-mgmt`;
export const ADMIN_URL = `${DASHBOARD_URL}/admin`;
export const REGION_MGMT_URL = `${DASHBOARD_URL}/region-mgmt`;
export const PROVIDER_TYPE_URL = `${DASHBOARD_URL}/provider-type`;
export const INSURANCE_PLAN_URL = `${DASHBOARD_URL}/insurance-plan`;
export const SYMPTOM_URL = `${DASHBOARD_URL}/symptom`;
export const PROVIDER_REPS_URL = `${DASHBOARD_URL}/provider-reps`;

export const USER_URL = `${DASHBOARD_URL}/user`;
export const USERS_URL = `${USER_URL}/users`;
export const USERS_FORM_URL = `${USERS_URL}/:id`;
export const USERS_PROVIDERS_URL = `${USERS_FORM_URL}/providers`;

export const PROMO_URL = `${DASHBOARD_URL}/promo`;
export const PROMOS_URL = `${PROMO_URL}/promos`;
export const PROMOS_FORM_URL = `${PROMOS_URL}/:id`;

export const PROGRAM_URL = `${DASHBOARD_URL}/program`;
export const PROGRAMS_URL = `${PROGRAM_URL}/programs`;
export const PROGRAM_FORM_URL = `${PROGRAMS_URL}/:id`;
export const PROGRAM_MEMBER_URL = `${PROGRAM_FORM_URL}/members`;
export const PROGRAM_MEMBER_FORM_URL = `${PROGRAM_MEMBER_URL}/:pmId`;

export const AFFILIATION_URL = `${DASHBOARD_URL}/affiliation`;
export const AFFILIATIONS_URL = `${AFFILIATION_URL}/affiliations`;
export const AFFILIATION_FORM_URL = `${AFFILIATIONS_URL}/:id`;

export const WELCOM_MESSAGE_URL = `${DASHBOARD_URL}/welcome-message`;

export const MEMBER_HOME_SCREEN_URL = `${DASHBOARD_URL}/member-home-screen`;
export const MEMBER_HOME1_SCREEN_URL = `${MEMBER_HOME_SCREEN_URL}/home-adult`;
export const MEMBER_HOME2_SCREEN_URL = `${MEMBER_HOME_SCREEN_URL}/home-kid`;
export const MEMBER_HOME3_SCREEN_URL = `${MEMBER_HOME_SCREEN_URL}/home-pet-cat`;
export const MEMBER_HOME4_SCREEN_URL = `${MEMBER_HOME_SCREEN_URL}/home-pet-dog`;

export const CATEGORY_SETUP_URL = `${PROCEDURE_MGMT_URL}/category-setup`;
export const CATEGORY_SETUP_FORM_URL = `${CATEGORY_SETUP_URL}/:id`;
export const SUPPLIES_SETUP_URL = `${PROCEDURE_MGMT_URL}/supplies-setup`;
export const SUPPLIES_SETUP_FORM_URL = `${PROCEDURE_MGMT_URL}/supplies-setup/:id`;
export const AT_HOME_LABS_URL = `${PROCEDURE_MGMT_URL}/at-home-lab`;
export const AT_HOME_LABS_FORM_URL = `${PROCEDURE_MGMT_URL}/at-home-lab/:id`;

export const BIOMARKER_URL = `${PROCEDURE_MGMT_URL}/biomarker-setup`;
export const QUICKLINKS_URL = `${PROCEDURE_MGMT_URL}/quick-links`;
export const BIOMARKER_FORM_URL = `${BIOMARKER_URL}/:id`;

export const MEDICATION_SETS_URL = `${PROCEDURE_MGMT_URL}/medication-sets-setup`;
export const MEDICATION_SETS_FORM_URL = `${MEDICATION_SETS_URL}/:id`;

export const PROCEDURE_SETUP_URL = `${PROCEDURE_MGMT_URL}/procedure-setup`;
export const PROCEDURE_SETUP_FORM_URL = `${PROCEDURE_MGMT_URL}/procedure-setup/:id`;
export const INSURANCE_PLANS_URL = `${INSURANCE_PLAN_URL}/insurance-plans`;
export const INSURANCE_PLAN_FORM_URL = `${INSURANCE_PLANS_URL}/:id`;
export const SPECIALTY_URL = `${DASHBOARD_URL}/specialty`;
export const SPECIALITY_FORM_URL = `${SPECIALTY_URL}/:id`;

export const PRM_DASHBOARD_URL = `${DASHBOARD_URL}/prm`;
export const PROFILE_SETUP_URL = `${PRM_DASHBOARD_URL}/profile-setup`;
export const MY_PROVIDERS_URL = `${PRM_DASHBOARD_URL}/my-providers`;
export const MY_PROVIDERS_DETAIL_URL = `${PRM_DASHBOARD_URL}/my-providers/:id`;
export const COMPLAINTS_URL = `${PRM_DASHBOARD_URL}/complaints`;
export const COMPLAINTS_DETAIL_URL = `${COMPLAINTS_URL}/:id`;
export const PRM_DASHBOARD_STATS_URL = `${PRM_DASHBOARD_URL}/statistics`;

export const SYMPTOMS_URL = `${SYMPTOM_URL}/symptoms`;
export const SYMPTOMS_FORM_URL = `${SYMPTOMS_URL}/:id`;

export const CONTENT_DASHBOARD_URL = `${DASHBOARD_URL}/content`;
export const MEMBER_FAQ_URL = `${CONTENT_DASHBOARD_URL}/member-faq`;
export const MEMBER_FAQ_FORM_URL = `${MEMBER_FAQ_URL}/:id`;
export const MEMBER_TERM_URL = `${CONTENT_DASHBOARD_URL}/member-term-condition`;
export const MEMBER_TERM_FORM_URL = `${MEMBER_TERM_URL}/:id`;
export const MEMBER_VIDEO_URL = `${CONTENT_DASHBOARD_URL}/member-video`;
export const MEMBER_VIDEO_FORM_URL = `${MEMBER_VIDEO_URL}/:id`;

export const PROVIDER_FAQ_URL = `${CONTENT_DASHBOARD_URL}/provider-faq`;
export const PROVIDER_FAQ_FORM_URL = `${PROVIDER_FAQ_URL}/:id`;
export const PROVIDER_TERM_URL = `${CONTENT_DASHBOARD_URL}/provider-term-condition`;
export const PROVIDER_TERM_FORM_URL = `${PROVIDER_TERM_URL}/:id`;
export const PROVIDER_VIDEO_URL = `${CONTENT_DASHBOARD_URL}/provider-video`;
export const PROVIDER_VIDEO_FORM_URL = `${PROVIDER_VIDEO_URL}/:id`;
export const PRIVACY_POLICY_URL = `${CONTENT_DASHBOARD_URL}/privacy-policy`;
export const PRIVACY_POLICY_FORM_URL = `${PRIVACY_POLICY_URL}/:id`;
export const PROGRAM_CANCELLATION_POLICY_URL = `${CONTENT_DASHBOARD_URL}/program-cancellation-policy`;
export const PROGRAM_CANCELLATION_POLICY_FORM_URL = `${PROGRAM_CANCELLATION_POLICY_URL}/:id`;
export const MEMBER_INVITE_CONTENT_URL = `${CONTENT_DASHBOARD_URL}/member-invite-content`;
export const MEMBER_INVITE_CONTENT_FORM_URL = `${MEMBER_INVITE_CONTENT_URL}/:id`;
export const HOW_TO_PREPARE_URL = 'how-to-prepare';
export const PET_DIET_URL = `${CONTENT_DASHBOARD_URL}/pet-diet`;

export const SUGGESTION_DASHBOARD_URL = `${DASHBOARD_URL}/suggestion`;
export const SUGGESTION_PROVIDER_URL = `${SUGGESTION_DASHBOARD_URL}/provider-feedback`;
export const SUGGESTION_DETAIL_PROVIDER_URL = `${SUGGESTION_PROVIDER_URL}/provider-detail-feedback/:id`;
export const SUGGESTION_MEMBER_URL = `${SUGGESTION_DASHBOARD_URL}/member-feedback`;
export const SUGGESTION_DETAIL_MEMBER_URL = `${SUGGESTION_DASHBOARD_URL}/member-detail-feedback/:id`;
export const SUGGESTION_DETAIL_PROVIDER_BASE_URL = `${SUGGESTION_PROVIDER_URL}/provider-detail-feedback`;
export const SUGGESTION_DETAIL_MEMBER_BASE_URL = `${SUGGESTION_DASHBOARD_URL}/member-detail-feedback`;

export const GIVEAWAY_URL = `${DASHBOARD_URL}/giveaway`;
export const GIVEAWAYS_URL = `${GIVEAWAY_URL}/giveaways`;
export const GIVEAWAYS_FORM_URL = `${GIVEAWAYS_URL}/:id`;
export const GIVEAWAYS_CRITERIA_URL = `${GIVEAWAY_URL}/giveaway-criterias`;
export const GIVEAWAYS_CRITERIA_FORM_URL = `${GIVEAWAYS_CRITERIA_URL}/:id`;

export const PROVIDER_STATE_URL = `${DASHBOARD_URL}/provider-state`;
export const PROVIDER_STATES_URL = `${PROVIDER_STATE_URL}/provider-states`;
export const PROVIDER_STATES_FORM_URL = `${PROVIDER_STATES_URL}/:id`;

export const REV_GIVE_URL = `${DASHBOARD_URL}/rev-gives`;
export const REV_GIVES_URL = `${REV_GIVE_URL}/rev-gives-list`;
export const REV_GIVES_FORM_URL = `${REV_GIVES_URL}/:id`;

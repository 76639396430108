import { createSlice } from '@reduxjs/toolkit';
import {
  createPetDiets,
  downlaodPetDiets,
  listPetDiets,
} from './petDietsActions';

const INITIAL_STATE = {
  isLoading: false,
  petDiet: { docs: [] },
  uploadStatus: null,
};

export const petDietSlice = createSlice({
  name: 'petDiets',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
    clearError: (state) => {
      state.uploadStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listPetDiets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listPetDiets.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listPetDiets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.petDiet = action.payload;
    });
    builder.addCase(createPetDiets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPetDiets.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPetDiets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.uploadStatus = action.payload;
    });
    builder.addCase(downlaodPetDiets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(downlaodPetDiets.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(downlaodPetDiets.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
  clearError,
} = petDietSlice.actions;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';

import { PET_DIET_API_URL } from './petDietsUrls';

export const listPetDiets = createAsyncThunk(
  'petDiets/list',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PET_DIET_API_URL(`?where[termDate][equals]=null&sort=dietName&${query}`)).then(({ data }) => data);
  },
);

export const createPetDiets = createAsyncThunk(
  'petDiets/createPetDiets',
  async (params) => axios.post(
    PET_DIET_API_URL('/upload-pet-diets'),
    { ...params },
  ).then(({ data }) => {
    if (data?.uploadStatus?.validationErrors?.length === 0) {
      toast.success('Pet Diets Uploaded successfully');
    }
    return data;
  }),
);

export const downlaodPetDiets = createAsyncThunk(
  'petDiets/downlaodPetDiets',
  async () => axios.get(PET_DIET_API_URL('/download-pet-diets'))
    .then((data) => {
      try {
        const csvData = data.data;

        const now = new Date();
        const timestamp = now
          .toISOString()
          .replace(/T/, '_')
          .replace(/:/g, '-')
          .split('.')[0];
        const filename = `pet_diets_${timestamp}.csv`;

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading CSV:', error);
        return;
      }
      toast.success('Pet Diets downloaded successfully');
    }),
);
